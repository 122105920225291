@import '../scss/base.scss';
.container {
  background: #ffffff;
  overflow: hidden;
  position: relative;

  .loader {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-top: 150px;
    position: fixed;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffffb4;
  }

  .section {
    box-sizing: border-box;
    width: 100%;
    max-width: calc(1085px + 60px);
    padding: 0 30px;
    margin: 0 auto;
    @include mobile {
      padding: 0 15px;
    }

    &.categories {
      padding-bottom: 20px;
    }
  }
}
