@import '../../scss/base.scss';

.hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fffaf3;

  .top {
    width: 100%;
    background: radial-gradient(
        238.54% 379.33% at 51.74% 42.27%,
        rgba(255, 243, 225, 0.8) 0%,
        rgba(0, 201, 118, 0.8) 47.33%
      ),
      #ffffff;

    @include mobile {
      margin-top: 0px;
    }

    .xMas {
      display: flex;
      justify-content: center;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      flex-direction: column;
      background-image: url('/home/hero-background1440px.png');
      @include tablet {
        background-image: url('/home/hero-background-mobile.png');
      }

      .imageContainer {
        width: 100%;
        max-height: 273px;

        @media screen and (max-width: 900px) {
          max-height: unset;
        }

        @include mobile {
          max-height: 140px;
        }

        .image {
          width: 100%;
          position: relative;
          max-width: 970px;
          bottom: 25px;
          @media screen and (max-width: 920px) {
            bottom: 10px;
          }
          @media screen and (max-width: 960px) {
            bottom: 30px;
          }
          @media screen and (max-width: 900px) {
            bottom: -65px;
          }

          &.desktop {
            margin-left: auto;
            margin-right: auto;

            @include mobile {
              display: none;
            }
          }

          &.mobile {
            display: none;
            bottom: -5px !important;

            @include mobile {
              margin-left: auto;
              margin-right: auto;
              display: block;
              width: 100%;
              max-width: 330px;
            }

            @media (max-width: 375px) {
              margin-top: 0px;
            }

            @media (max-width: 360px) {
              margin-top: 4px;
              bottom: -3px;
            }
          }
        }
      }
    }
  }

  .bottom {
    box-sizing: border-box;
    width: 100%;
    max-width: 910px;
    padding: 50px 30px 40px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mobile {
      padding: 40px 15px 30px 15px;
    }

    > h1 {
      width: 100%;
      max-width: 634px;
      margin: 0;
      font-family: $mawoo-font-black;
      font-weight: unset;
      font-size: 50px;
      line-height: 63px;
      text-align: center;
      color: $baseGreen;

      @include mobile {
        max-width: 345px;
        font-size: 30px;
        line-height: 37px;
      }
    }

    > h2 {
      width: 100%;
      max-width: 500px;
      margin: 0;
      margin-top: 30px;
      margin-bottom: 30px;
      font-family: $mawoo-font-medium;
      font-weight: unset;
      font-size: 25px;
      line-height: 31px;
      text-align: center;
      color: $baseBlack;

      @include mobile {
        max-width: 350px;
        margin-top: 20px;
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 22px;
      }
    }

    .searchBar {
      box-sizing: border-box;
      width: 100%;
      height: 80px;
      z-index: 10;

      @include mobile {
        height: 60px;
      }

      button {
        @include mobile {
          height: 40px;
        }
      }
    }
    .trustpilot {
      width: 100%;
      height: 24px;
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}
