@import '../../scss/base.scss';

.section {
  box-sizing: border-box;
  width: 100%;
  max-width: calc(1085px + 60px);
  margin: 0px auto;
  padding: 0 30px;
  margin-top: 40px;
  height: 760px;
  @include mobile {
    height: unset;
    padding: 0;
    margin: 30px 0;
  }

  a {
    color: inherit;
    text-underline-offset: 2px;
  }

  .header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @include mobile {
      justify-content: space-between;
      margin-bottom: 20px;
      padding-left: 10px;
    }

    >h2 {
      margin: 0;
      @include title;

      @include mobile {
        font-size: 20px;
        margin: 0;
      }
    }

    .swiperNav {
      display: flex;

      a {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 18px;
        font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
        color: #00ad66;
        text-decoration: underline;
        text-underline-offset: 5px;
        margin-right: 20px;

        @media (max-width: 1134px) {
          right: calc(30px + (35px * 2) + 20px + 20px);
        }

        @include mobile {
          position: static;
          height: 23px;
          min-width: 54px;
          font-size: 16px;
        }
      }

      a:hover {
        color: #008c54;
        text-decoration: underline;
        text-underline-offset: 5px;
        // border-bottom: 1px solid #008c54;
      }

      .swiperButton {
        box-sizing: border-box;
        top: 93px;
        height: 35px;
        width: 35px;
        background-color: #ffffff;
        border: 2px solid #393939;
        border-radius: 50%;
        cursor: pointer;
        outline: none;

        @include mobile {
          display: none;
        }
      }

      .swiperButton:hover {
        border: 2px solid rgba(0, 173, 102, 0.7);
      }

      .swiperPrev {
        margin-right: 10px;
        background-size: 9px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('/prev-black.svg');
      }

      .swiperPrev:hover {
        background-image: url('/prev-green.svg');
      }

      .swiperNext {
        background-size: 9px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('/next-black.svg');
      }

      .swiperNext:hover {
        background-image: url('/next-green.svg');
      }

      .disabledPrev,
      .disabledNext {
        box-sizing: border-box;
        top: 93px;
        height: 35px;
        width: 35px;
        background-color: #ffffff;
        border: 2px solid #393939;
        border-radius: 50%;

        background-size: 9px;
        background-position: center;
        background-repeat: no-repeat;

        outline: none;
        cursor: auto;
        pointer-events: none;
        opacity: .35;
      }

      .disabledPrev {
        margin-right: 10px;
        background-image: url('/prev-black.svg');
      }

      .disabledNext {
        background-image: url('/next-black.svg');
      }
    }
  }

  .cardWrapper {

    @include mobile {
      >div {
        >div {
          >div:last-child {
            margin-right: 15px;
          }

          >div:first-child {
            margin-left: 15px;
          }

          >div {


            width: auto !important;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }

    .pair {
      width: auto !important;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include mobile {
        align-items: flex-start;
      }
    }

    .heartWrapper {
      width: 35px;
      height: 35px;
      background-color: #ffffff88;
      border-radius: 50%;
      z-index: 8;
      top: 20px;
      right: 20px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;

      .heart {
        width: 20px;
        height: 20px;
        background-image: url('/puppy/heart.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        @include mobile {
          width: 14px;
          height: 14px;
        }

        &.isFavourite {
          background-image: url('/puppy/heart-favourite.svg');
        }
      }
    }

    .heart:hover {
      background-image: url('/puppy/heart-hover.svg');
    }

    .cardItemWrapper {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 10px;
      border-radius: 8px;
      transition: all 0.5s ease-in;

      &:hover {
        box-shadow: 0px 25px 40px -30px rgb(0 0 0 / 35%);
      }

      .cardItemWrapperName__Wrapper {
        a {
          display: flex;
          justify-content: space-between;
        }

        .cardItemWrapperName__Name,
        .cardItemWrapperName__Price {
          font-family: 'Circular Std', Helvetica, Arial, sans-serif;
          font-weight: bold;
          font-size: 20px;
          line-height: 32px;
          color: #393939;

          p {
            margin: 20px 0 0;
          }
        }
      }

      .cardItemWrapperGender__Wrapper {
        font-family: Circular Std book, Helvetica, Arial, sans-serif;
        color: #39393999;
        font-size: 14px;

        p {
          margin: 10px 0;
        }
      }

      .cardItemWrapperLink__Wrapper {
        a {
          font-family: 'Circular Std', Helvetica, Arial, sans-serif;
          font-size: 14px;
          color: #393939;
          text-decoration: none;

          &:after {
            content: '';
            display: block;
            width: 30%;
            height: 1px;
            background-color: #393939;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
