@import '../../scss/base.scss';

.partner {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 32px 0;

  h2 {
    text-align: center;
  }

  .brands {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;

    .image {
      width: 100%;
      max-width: 180px;
      @include mobile {
        max-width: 150px;
      }
    }
  }
}
